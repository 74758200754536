import './index.scss'
import Swiper from 'swiper/swiper-bundle.min.js'

//banner效果
var bannerswiper = new Swiper(".index-banner .mySwiper", {
  // loop: true,
  speed: 700,
  effect : 'fade',
  autoplay: { 
    delay: 6000,
    stopOnLastSlide: false,
    disableOnInteraction: false,
  },
  on:{
    init: function(swiper){
      $('.index-banner .banner-text .item').eq(this.activeIndex).addClass('active').siblings().removeClass('active')
      $('.index-banner .banner-page .item').eq(this.activeIndex).addClass('active').siblings().removeClass('active')
    }, 
    transitionStart: function(swiper){
      let index = this.realIndex
      $('.index-banner .banner-text .item').eq(index).addClass('active').siblings().removeClass('active')
      $('.index-banner .banner-page .item').eq(index).addClass('active').siblings().removeClass('active')
    },
  },
});

$('.index-banner .banner-page .item').on('click',function(){
  let index = $(this).index()
  bannerswiper.slideTo(index)
})


//产品效果

!(function(){
  $('.index-pro .pro-title h2').eq(1).addClass('active')
  $('.index-pro .pro-cont .item').eq(1).addClass('active')
})()

$('.index-pro .pro-title h2').hover(function(){
  let index = $(this).index()
  $('.index-pro .pro-title h2').eq(index).addClass('active').siblings().removeClass('active')
  $('.index-pro .pro-cont .item').eq(index).addClass('active').siblings().removeClass('active')
})

var proswiper = new Swiper(".pro-cont .item:nth-child(1) .pro-swiper .right .mySwiper", {
  spaceBetween: 10,
  slidesPerView: 3,
  watchSlidesProgress: true,
  observer: true,
  observeParents: true,
});
var proswiper2 = new Swiper(".pro-cont .item:nth-child(1) .pro-swiper .left .mySwiper2", {
  spaceBetween: 10,
  observer: true,
  observeParents: true,
  thumbs: {
    swiper: proswiper,
  },
});

var proswiper3 = new Swiper(".pro-cont .item:nth-child(2) .pro-swiper .right .mySwiper", {
  spaceBetween: 10,
  slidesPerView: 3,
  watchSlidesProgress: true,
  observer: true,
  observeParents: true,
});
var proswiper4 = new Swiper(".pro-cont .item:nth-child(2) .pro-swiper .left .mySwiper2", {
  spaceBetween: 10,
  observer: true,
  observeParents: true,
  thumbs: {
    swiper: proswiper3,
  },
});

var proswiper5 = new Swiper(".pro-cont .item:nth-child(3) .pro-swiper .right .mySwiper", {
  spaceBetween: 10,
  slidesPerView: 3,
  watchSlidesProgress: true,
  observer: true,
  observeParents: true,
});
var proswiper6 = new Swiper(".pro-cont .item:nth-child(3) .pro-swiper .left .mySwiper2", {
  spaceBetween: 10,
  observer: true,
  observeParents: true,
  thumbs: {
    swiper: proswiper5,
  },
});